///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, {useState} from 'react'
import graphLogoImg from '../images/graph.png'
import excelToXmlImg from '../images/excelToXml.png'
import powerImg from '../images/power.png'
import manageImg from '../images/manage.png'
import checkDbImg from '../images/checkDB.png'

import CookieConsent from "react-cookie-consent";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faX, faArrowRight, faChartColumn, faMoneyBillTransfer, faRectangleList, faListCheck, faFileCircleCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';
import { subDisplayData } from '../services/SubscriptionsService'
import { formatNr } from '../services/FormatService'
import ModalSetValue from './MainAppComponents/ModalSetValue'
import { logEvent } from "firebase/analytics"
import { analytics } from '../services/FirebaseService'

const WeclomePage = () => {

    const currentYear = new Date().getFullYear();

    const [totalSubs] = useState(subDisplayData)

    const navigate = useNavigate()

    // const scrollToId = (id) => {
    //     const elem = document.getElementById(id)
    //     if(elem === null) return
    //     elem.scrollIntoView({behavior: 'smooth'}, true)
    // }

    const [modal, setModal] = useState(false)

    // const priceWithTax = (price) => {
    //     return ((19/100) * (+price)) + (+price)
    // }

    const getOldPrice = (price) => {
        return (parseInt((+price) * (50/100) + (+price)) - 1) + .99
        // return (parseInt(((19/100) * beforeTax) + beforeTax) - 1) + .99
    }

  return (
    <>
    <main className='welcome-wrapper'>

        <section className='welcome-section left-logo'>
            <div className='welcome-content'>
                <div className='welcome-logo'>
                    <img src={graphLogoImg} placeholder='Graph' alt='Graph' />
                </div>
                <div className='big-title button-r'>
                    <h1 id='Program Intrastat' name="Program Intrastat" title={`Intrastat ${currentYear}`}>Program Intrastat</h1>
                    <p title='Ce este programul de intrastat'>
                        Program pentru a realiza <b>declarația intrastat gratuit</b>.<br />
                        Simplu, rapid și la zi cu toate actualizările,<br />
                        această platformă online vă pune la dispoziție tot<br />
                        ce aveți nevoide pentru declarația intrastat: <br />
                        Nomnclator coduri vamale la zi,<br /> 
                        calcul valoare statistică, <br />
                        modele de completare a declarației Intrastat, <br />
                        și multe altele.<br />
                    </p>
                    <p title='Intrastat gratuit'>
                        Încearcă acum și benefiziază <span className='contrast-text heavy-text'>GRATUIT</span><br />
                        de program pentru 6 luni.
                    </p>
                    <a href='https://landing.programintrastat.ro/' className='button-main'>Mai multe</a>
                </div>
            </div>
        </section>

        <div className='welcome-section'>
            <div className='welcome-content try-free' onClick={() => navigate('/register')}>
                <div>
                    <h2 id='IntrastatGratuit'>Încearcă acum GRATUIT</h2>
                </div>
            </div>
        </div>

        <div className='welcome-section' id='lista-coduri-intrastat'>
            <h1>Cauți coduri Intrastat NC {currentYear}?</h1>
            <div className='welcome-content try-free' onClick={() => {
                logEvent(analytics, 'search_nc_code_main_page');
                setModal(true);
                }}>
                <div>
                    <h2 id='ListaCoduri' title='Lista coduri Intrastat'>Listă completă coduri Intrastat {currentYear}</h2>
                </div>
            </div>
        </div>

        <h1 className='welcome-title' id='how-it-works'>Cum funcționează?</h1>
        <article className='welcome-section two-pane'>
            <div className='welcome-content white-section pane'>
                <div>
                    <h1>Din Excel în XML</h1>
                    <ul>
                        <li>Importați fișierul Excel (.xlsx). <a
                        href="https://landing.programintrastat.ro/ce-declar-la-intrastat"
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                        Ce trebuie să conțină fișierul Excel?
                        </a>
                        </li>
                        <li>Fișierul importat se procesează automat.</li>
                        <li>Se calculează valoarea statistică.</li>
                        <li>Erorile sunt evidențiate într-un tabel editabil.</li>
                        <li>Se generează fișierul XML necesar.</li>
                    </ul>
                    <img src={excelToXmlImg} alt="excelToExml" className='pane-right-img' />
                </div>
            </div>
            <div className='welcome-content blue-section pane'>
                <div>
                    <h1>Procesare și verificare</h1>
                    <ul>
                        <li>Puteți edita datele din fișierul Excel direct pe platformă.</li>
                        <li>Validarea codurilor TVA(VAT/CIF) necesare pentru livrări
                            se verifică în baza de date oficială VIES.
                        </li>
                        <li>Cursul valutar este calculat pe platformă în funcție de data facturii
                            după cursul oficial BNR.
                        </li>
                    </ul>
                    <img src={checkDbImg} alt="excelToExml" className='pane-right-img' />
                </div>
            </div>
        </article>

        <h1 className='welcome-title'>Ce oferim?</h1>
        <div className='bubbles'>
            <div className='bubble'>
                <div className='bubble-icon'><FontAwesomeIcon icon={faChartColumn} /></div>
                <h1>Valoare Statistică</h1>
                <div>Calcul automat și configurabil al valorii statistice</div>
            </div>
            <div className='bubble'>
                <div className='bubble-icon'><FontAwesomeIcon icon={faMoneyBillTransfer} /></div>
                <h1>Conversie Valutară</h1>
                <div>Conversii valutare bazate pe cursul oficial BNR din data facturii</div>
            </div>
            <div className='bubble'>
                <div className='bubble-icon'><FontAwesomeIcon icon={faRectangleList} /></div>
                <h1>Coduri Vamale</h1>
                <div>Listă completă a codurilor vamale Intrastat la zi</div>
            </div>
            <div className='bubble'>
                <div className='bubble-icon'><FontAwesomeIcon icon={faListCheck} /></div>
                <h1>Validare Coduri TVA</h1>
                <div>Validarea automată a codurilor de TVA folosid baza de date oficială VIES</div>
            </div>
            <div className='bubble'>
                <div className='bubble-icon'><FontAwesomeIcon icon={faFileCircleCheck} /></div>
                <h1>Generare XML</h1>
                <div>Generarea fișierelor XML compatibile cu platforma oficială INTRASTAT</div>
            </div>
            <div className='bubble'>
                <div className='bubble-icon'><FontAwesomeIcon icon={faTriangleExclamation} /></div>
                <h1>Identificare Erori</h1>
                <div>Identificarea erorilor în timp real și oferirea sugestilor pentru a le rezolva</div>
            </div>
        </div>
        <article className='welcome-section two-pane'>
            <div className='welcome-content blue-section pane'>
                <div>
                    <h1>Putere de procesare</h1>
                    <ul>
                        <li>Puteți importa fișiere mici, dar și fișiere cu zeci de mii de linii cu ușurință.</li>
                        <li>Timpul de generare până și al fișierelor foarte mari este redus drastic.</li>
                        <li>Timp de generare al fișierelor mari (&gt; 10.000 linii) de sub 1 minut.</li>
                        <li>Verificarea erorilor din tabel în timp real și cât mai rapid.</li>
                        <li>Calcule pentru fișiere mari efectuate în doar câteva secunde.</li>
                    </ul>
                    <img src={powerImg} alt="power" className='pane-right-img' />
                </div>
            </div>
            <div className='welcome-content white-section pane'>
                <div>
                    <h1>Administrare</h1>
                    <ul>
                        <li>Vizualizați statistici legate de contul dvs. în timp real.</li>
                        <li>Vă puteți administra întreaga listă de clienți pe platformă. (declaranți terți)</li>
                        <li>Aveți posibilitatea de a crea machete de lucru speciale pentru fiecare client în parte(declaranți terți)</li>
                    </ul>
                    <img src={manageImg} alt="manage" className='pane-right-img' />
                </div>
            </div>
        </article>

        <h1 className='welcome-title' id="pricing">Tarife</h1>
        <h2 className='welcome-subtitle' style={{marginBottom:'-3em'}}>Pentru firme independente</h2>

        <article className='welcome-section pricing-pane'>
            {
                totalSubs.map((sub, i) => {
                    if (i === totalSubs.length - 1) return null
                    return (
                    <div className='welcome-content pricing-pane' key={i} onClick={() => navigate('/register')}>
                        <div>
                            <h1>{sub.name}</h1>
                            <div className='old-price'>€{getOldPrice(sub.price)}/lună</div>
                            <div className='price'>€{sub.price}/lună</div>
                            <div className='plan-info'>
                                <p>
                                    <b>Firme: </b>{sub.options.companies}
                                </p>
                                <p>
                                    <b>Linii: </b>{formatNr(sub.lines)} /lună
                                </p>
                                <p>
                                    <b>Machete: </b>
                                    <span style={{fontSize:"1.1rem"}}>{sub.options.layouts}</span>
                                </p>
                                <p data-tip data-for={`${sub.options.valStat ? 'pricing-available' : 'pricing-not-available'}`}>
                                    <b>Valoare statistică: </b>
                                    <span 
                                        style={{color:`${sub.options.valStat ? 'green' : 'red'}`}}>
                                        <FontAwesomeIcon icon={sub.options.valStat ? faCircleCheck : faX} />
                                    </span>
                                </p>
                                <p data-tip data-for={`${sub.options.vat ? 'pricing-available' : 'pricing-not-available'}`}>
                                    <b>Verificare CIF: </b>
                                    <span 
                                        style={{color:`${sub.options.vat ? 'green' : 'red'}`}}>
                                        <FontAwesomeIcon icon={sub.options.vat ? faCircleCheck : faX} />
                                    </span>
                                </p>
                                <p data-tip data-for={`${sub.options.exchange ? 'pricing-available' : 'pricing-not-available'}`}>
                                    <b>Convertire valută BNR: </b>
                                    <span 
                                        style={{color:`${sub.options.exchange ? 'green' : 'red'}`}}>
                                        <FontAwesomeIcon icon={sub.options.exchange ? faCircleCheck : faX} />
                                    </span>
                                </p>
                            </div>
                            <FontAwesomeIcon onClick={() => navigate('/register')} icon={faArrowRight} className="arrow-button" />
                        </div>
                    </div>
                    )
                })
            }
        </article>

        <h2 className='subtitle'>Pentru declaranți terți</h2>

        <div className='welcome-section big-pricing' onClick={() => navigate('/register')}>
            <div>
                <h1>{totalSubs[totalSubs.length - 1].name}</h1>
                <div className='old-price'>€{getOldPrice(totalSubs[totalSubs.length - 1].price)}/lună</div>
                <div className='price'>€{totalSubs[totalSubs.length - 1].price}/lună</div>
                    <div className='plan-info'>
                        <p>
                            <b>Firme: </b>{totalSubs[totalSubs.length - 1].options.companies}
                        </p>
                        <p>
                            <b>Linii: </b>{formatNr(totalSubs[totalSubs.length - 1].lines)} /lună
                        </p>
                        <p>
                            <b>Machete: </b>
                            <span style={{fontSize:"1.5rem"}}>{totalSubs[totalSubs.length - 1].options.layouts}</span>
                        </p>
                        <p data-tip data-for="pricing-available">
                            <b>Valoare statistică: </b>
                            <span style={{color:"green"}}><FontAwesomeIcon icon={faCircleCheck} /></span>
                        </p>
                        <p data-tip data-for="pricing-available">
                            <b>Verificare CIF: </b>
                            <span style={{color:"green"}}><FontAwesomeIcon icon={faCircleCheck} /></span>
                        </p>
                        <p data-tip data-for="pricing-available">
                            <b>Convertire valută BNR: </b>
                            <span style={{color:"green"}}><FontAwesomeIcon icon={faCircleCheck} /></span>
                        </p>
                    </div>
                    <FontAwesomeIcon icon={faArrowRight} className="arrow-button" />
            </div>
        </div>
        <div>
            <small className='text-faded'>Petru oferte personalizate vă rugăm să ne contactați.</small> <br />
            <small className='text-faded'>Plata se efectuează o dată pe an (câte 12 luni).</small> <br />
            <small className='text-faded'>Prețurile afișate nu includ TVA.</small> <br />
        </div>

        <h1 className='welcome-title' id="despre-intrastat" title='Despre Intrastat'>Despre Intrastat {currentYear}</h1>
        <div className='welcome-section' id='informatii-intrastat'>
            <section className='simple-description' id='cine-declara-intrastat'>
                <article id='ce-este-intrastat'>
                    <h1 title='Ce este Intrastat'>Ce este Intrastat?</h1>
                    <p>Intrastat este denumirea sistemului de colectare a datelor statistice privind comertul cu bunuri între statele membre ale Uniunii Europene. Acest sistem statistic este operational de la 1 ianuarie 1993 la nivelul UE.</p>
                </article>

                <article id='cine-declara-intrastat'>
                    <h1 title='Cine declara Intrastat'>Cine declară Intrastat?</h1>
                    <p>Obligaţia furnizării datelor pentru sistemul statistic Intrastat revine operatorilor economici care:</p>
                    <ul>
                        <li>sunt înregistraţi în scopuri de taxă pe valoare adaugată (au cod de identificare fiscală)</li>
                        <li>realizează schimburi de bunuri cu alte state membre ale Uniunii Europene</li>
                        <li>valoarea totală anuală a schimburilor de bunuri pentru fiecare din cele două fluxuri, introduceri şi respectiv expedieri, depăşeşte nivelul pragurilor valorice Intrastat stabilite pentru fiecare an</li>
                    </ul>

                    <p title='Ce se declara in Intrastat'>Operatorii economici furnizori de date Intrastat trebuie să transmită lunar la INS, în format electronic, o declaraţie statistică în care sunt folosite următoarele noţiuni specifice:</p>
                    <ul>
                        <li>introducere de bunuri: sosirea bunurilor în România din Statele Membre ale Uniunii Europene</li>
                        <li>expediere de bunuri: ieşirea bunurilor din România către Statele Membre ale Uniunii Europene</li>
                    </ul>
                </article>

                <article id='cand-trebuie-transmis-intrastat'>
                    <h1>Când trebuie transmis Intrastat?</h1>
                    <p>Termenul limită de transmitere a datelor Intrastat la INS îl reprezintă întotdeauna data de 15 a lunii următoare celei de referinţă.</p>
                </article>
            </section>
            <div>Pentru mai multe informații, <a href='http://www.intrastat.ro/di.php' target='_blank' rel='noreferrer'>accesați site-ul oficial INTRASTAT.</a></div>
        </div>
        <p title='Coduri vamale Romania Intrastat'></p>
    </main>

    {modal && 
        <ModalSetValue 
        values = {{type: 0}}
        setValue = {false}
        close = {() => setModal(false)}
    />}

    <ReactTooltip effect="solid" id='pricing-not-available' type='error'>Nu conține</ReactTooltip>
    <ReactTooltip effect="solid" id='pricing-available' type='success'>Conține</ReactTooltip>

    <CookieConsent
        location="bottom"
        buttonText="De acord"
        cookieName="agreeConfidenPolicy"
        style={{ background: "#2B373B", paddingBlock:'2em' }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        contentStyle={{flex:'1 0 80%'}}
        expires={150}
        overlay={true}
        >
        <div>
            Acest website folosește cookieuri pentru a îmbunătăți experiența utilizatorilor.{" "}
        </div>
        <Link to={'/politica-de-confidentialitate'} 
        style={{ fontSize: "12px", color:'white' }}>Citește mai multe
        </Link>
    </CookieConsent>
    </>
  )
}

export default WeclomePage