///---------------------------------------------------------------------------
//! Copyright (C) ASQUARED SRL - All Rights Reserved
//* Unauthorized copying of this file, via any medium is strictly prohibited
//* Proprietary and confidential
//* Written by Alexandru Gârbacea <g99.alex@yahoo.com>, September 2022
//? @author g99.alex@yahoo.com
///---------------------------------------------------------------------------

import React, { useState, useRef, useEffect } from "react";

import { useAuth } from "../../context/AuthContext";
import { getOneMonthDif } from "../../services/DateService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSpinner,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { createNotification } from "../../services/NotificationService";
import HandleExcel from "./HandleExcel";
import {
  getExcelSheets,
  getHeaders,
  getSheetValues,
} from "../../services/ExcelService";

const ImportExcel = () => {
  const FINAL_STEP = 4;
  const SLEEP_TIME = 1000;

  const today = getOneMonthDif(-1);

  const [loading, setLoading] = useState(false);
  const [handle, setHandle] = useState(false);
  const [done, setDone] = useState(false);
  const [step, setStep] = useState(0);
  const [percent, setPercent] = useState(0);
  const [data, setData] = useState({
    file: "",
    sheets: "",
    headers: "",
    layout: 0,
    year: today.year,
    month: today.month,
    tert: false,
    compFor: 0,
    valStat: false,
  });
  const [generating, setGenerating] = useState(false);
  const [fileSheets, setFileSheets] = useState([]);

  const inputFile = useRef();

  const { layouts, userData, companies } = useAuth();

  useEffect(() => {
    step === FINAL_STEP && setDone(true);
    setPercent(step > 0 ? (step * 100) / FINAL_STEP : 0);
  }, [step]);

  useEffect(() => {
    done && generatingFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done]);

  const checkValues = () => {
    if (data.file === "") {
      createNotification("error", `Fișierul este invalid`);
      return false;
    }
    if (parseInt(data.year) < 0) {
      createNotification("error", `Anul este invalid`);
      return false;
    }
    if (parseInt(data.month) < 1 || data.month > 12) {
      createNotification("error", `Luna este invalidă`);
      return false;
    }
    return true;
  };

  const generatingFunc = async () => {
    setGenerating(true);
    createNotification("information", `Se generează`);
    const error = !checkValues();
    if (error) {
      setDone(false);
      setStep(0);
      setGenerating(false);
      return false;
    }
    await new Promise((r) => setTimeout(r, SLEEP_TIME));
    setHandle(true);
  };

  const handleExcelUpload = async (files) => {
    if (files.length < 1) {
      return createNotification("error", `Fișierul este invalid`);
    }
    let file = files[0];
    if (
      file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      file.type !== "application/vnd.ms-excel"
    ) {
      return createNotification("error", `Fișierul este invalid`);
    }
    setData((prev) => ({ ...prev, file: file }));

    setLoading(true);
    const sheets = getExcelSheets(file);
    await new Promise((r) => setTimeout(r, 200));
    if (sheets.length < 1) {
      setLoading(false);
      return createNotification("error", `Fișierul nu conține date.`);
    }
    const sheetValues = getSheetValues(file);
    await new Promise((r) => setTimeout(r, 200));
    setFileSheets(sheets);
    setData((prev) => ({ ...prev, sheets: sheets, sheetValues: sheetValues }));
    await new Promise((r) => setTimeout(r, 200));
    setLoading(false);
  };

  const finishExcelUpload = async (headerIdx = 0) => {
    setLoading(true);
    const headers = getHeaders(data.sheetValues[headerIdx]);
    setData((prev) => ({
      ...prev,
      headers: headers,
      sheetValues: prev.sheetValues[headerIdx],
    }));
    await new Promise((r) => setTimeout(r, 200));
    setLoading(false);
    createNotification("success", `Fișierul a fost importat`);
    const nextStep = step + 1;
    setStep(nextStep);
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  const handleSteps = (dir) => {
    const nextStep = step + dir;
    if (nextStep === 0) {
      // Going back to beginning resets imported file
      setFileSheets([]);
    }
    setStep(nextStep);
  };

  const autoSetFromLayout = () => {
    // return if user is not on tert account
    if (!userData.data.tert) return false;
    const idx = parseInt(data.layout);
    // check if the layout is for users company or else set tert
    if (layouts[idx].company === companies[0].cif) {
      setData((prev) => ({ ...prev, tert: false }));
    } else {
      setData((prev) => ({ ...prev, tert: true }));
      // check if company still exists still
      let toReturn = -1;
      toReturn = companies.map((c) => c.cif).indexOf(layouts[idx].company);
      if (toReturn !== -1) setData((prev) => ({ ...prev, compFor: toReturn }));
    }
  };

  const autoSetLayout = () => {
    const fName = data.file !== "" ? data.file.name : "";
    if (fName === "") return;
    layouts.forEach((val, index) => {
      // If name is exactly matching
      if (
        fName
          .toString()
          .toUpperCase()
          .includes(val.name.toString().toUpperCase()) ||
        val.name
          .toString()
          .toUpperCase()
          .includes(fName.toString().toUpperCase())
      ) {
        setData((prev) => ({ ...prev, layout: index }));
        return;
      }

      // Split names of file to check match
      const fNameSplit = fName.toUpperCase().split(/[^a-z]/);
      const containsWords = fNameSplit.some(
        (word) => val.name.toUpperCase().indexOf(word) !== -1
      );
      if (containsWords) {
        setData((prev) => ({ ...prev, layout: index }));
        return;
      }
    });
  };

  // Download the example excel file
  const handleDownloadExcel = () => {
    const fileUrl = "/files/Exemplu_achizitii_programintrastat.xlsx";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "Exemplu_achizitii_programintrastat.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    handleUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    autoSetFromLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.layout]);

  useEffect(() => {
    autoSetLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.file]);

  return (
    <div className="import-wrapper">
      {!handle && (
        <div className="import-section">
          {step !== FINAL_STEP ? (
            <div>
              Pasul <b>{step + 1}</b> / {FINAL_STEP}
            </div>
          ) : (
            <div>
              <b>Finalizat</b>
            </div>
          )}
          <div className="width-wrapper">
            <div className="progress-bar-wrapper">
              <div className="progress-bar" style={{ width: `${percent}%` }}>
                {percent}%
              </div>
            </div>
          </div>

          {step !== 0 && step !== FINAL_STEP && (
            <div className="small-form-wrapper">
              <div className="steps-buttons">
                <div
                  className="button-secondary"
                  onClick={() => handleSteps(-1)}
                >
                  Pas anterior
                </div>
                <div
                  className="button-secondary selected-btn"
                  onClick={() => handleSteps(1)}
                >
                  {step + 1 !== FINAL_STEP ? "Pas următor" : "ÎNCARCĂ"}
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </div>
            </div>
          )}

          {step === 0 && (
            <div className="upload-section">
              <h2>Importă fișierul Excel</h2>
              {loading ? (
                <FontAwesomeIcon className="spinner" icon={faSpinner} />
              ) : !fileSheets.length > 0 ? (
                <>
                  <div className="upload-here" onClick={() => handleUpload()}>
                    <FontAwesomeIcon className="upload-icon" icon={faPlus} />
                    <input
                      type="file"
                      id="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => handleExcelUpload(e.target.files)}
                      ref={inputFile}
                      style={{ display: "none" }}
                    />
                  </div>
                  <p>
                    <a
                      href="https://landing.programintrastat.ro/ce-declar-la-intrastat"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ce trebuie să conțină fișierul Excel importat?
                    </a>
                  </p>
                  <p>
                    <span className="link-style" onClick={handleDownloadExcel}>
                      Descarcă exemplu de fișier Excel
                    </span>
                  </p>
                  <p>
                    <a href="https://programintrastat.ro/docs">
                      Instrucțiuni pas cu pas
                    </a>
                  </p>
                </>
              ) : (
                <div className="small-form-wrapper">
                  <div className="form-section-label small-form-section">
                    <p>Fișa de lucru</p>
                    <select
                      onChange={(e) => finishExcelUpload(e.target.value)}
                      name="tip"
                      id="tip"
                      className="input-text"
                      required
                    >
                      <option value={0}>- Alege din listă -</option>
                      {fileSheets.map((name, index) => {
                        return (
                          <option key={`${name} - ${index}`} value={index}>
                            {name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
            </div>
          )}

          {step === 1 && (
            <div className="small-form-wrapper">
              <h2>Macheta și data</h2>
              <div className="form-section-label small-form-section">
                <p>Machetă</p>
                <select
                  name="layout"
                  value={data.layout}
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, layout: e.target.value }))
                  }
                  id="layout"
                  className="input-text"
                >
                  {layouts.length > 0 &&
                    layouts.map((lay, index) => {
                      return (
                        <option
                          key={`${lay.dataId} - ${lay.name}`}
                          value={index}
                        >
                          {lay.name}
                        </option>
                      );
                    })}
                </select>

                <p>An</p>
                <input
                  type="number"
                  className="input-text"
                  id="year"
                  value={data.year}
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, year: e.target.value }))
                  }
                />

                <p>Lună</p>
                <input
                  type="number"
                  className="input-text"
                  id="month"
                  value={data.month}
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, month: e.target.value }))
                  }
                />

                {/* <div className='checkbox-wrapper' style={{marginTop:'2em'}}>
                            <input className='checkbox' value={data.valStat} defaultChecked={data.valStat}
                                onChange={(e) => setData(prev => ({...prev, valStat: e.target.checked}))}
                                type="checkbox" id="valStat" name="valStat" title='Calcul valoare statistică'/>
                            <label htmlFor="valStat">Calcul valoare statistică
                            </label>
                        </div> */}
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="small-form-wrapper">
              <h2>Firme</h2>
              <div className="form-section-label small-form-section">
                <p>{data.tert ? "Firmă terță" : "Firma mea"}</p>
                <input
                  disabled={true}
                  type="text"
                  className="input-text"
                  id="comp"
                  value={companies[0].name}
                />
                {userData.data.tert && (
                  <>
                    <div
                      className="checkbox-wrapper"
                      style={{ marginTop: "2em" }}
                    >
                      <input
                        className="checkbox"
                        value={data.tert}
                        defaultChecked={data.tert}
                        onChange={(e) =>
                          setData((prev) => ({
                            ...prev,
                            tert: e.target.checked,
                          }))
                        }
                        type="checkbox"
                        id="tert"
                        name="tert"
                        title="Declarant terț"
                      />
                      <label htmlFor="tert">Declarație terță</label>
                    </div>

                    {data.tert && (
                      <>
                        <p>Pentru firma</p>
                        <select
                          name="compT"
                          value={data.compFor}
                          onChange={(e) =>
                            setData((prev) => ({
                              ...prev,
                              compFor: e.target.value,
                            }))
                          }
                          id="compT"
                          className="input-text"
                        >
                          {companies.length > 1 &&
                            companies.map((comp, index) => {
                              if (index > 0)
                                return (
                                  <option
                                    key={`${comp.dataId} - ${comp.name}`}
                                    value={index}
                                  >
                                    {comp.name}
                                  </option>
                                );
                              else return null;
                            })}
                        </select>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="small-form-wrapper">
              <h2>Rezumat</h2>
              <div className="form-section-label small-form-section">
                <p>Fișier</p>
                <input
                  disabled={true}
                  type="text"
                  className="input-text"
                  id="fNameR"
                  value={data.file !== "" ? data.file.name : ""}
                />

                <p>Machetă</p>
                <input
                  disabled={true}
                  type="text"
                  className="input-text"
                  id="layoutR"
                  value={layouts[data.layout].name}
                />

                <p>An</p>
                <input
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, year: e.target.value }))
                  }
                  type="number"
                  className="input-text"
                  id="yearR"
                  value={data.year}
                />

                <p>Lună</p>
                <input
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, month: e.target.value }))
                  }
                  type="number"
                  className="input-text"
                  id="monthR"
                  value={data.month}
                />

                <p>Firma mea</p>
                <input
                  disabled={true}
                  type="text"
                  className="input-text"
                  id="compR"
                  value={companies[0].name}
                />

                {data.tert && (
                  <>
                    <p>Pentru firma</p>
                    <input
                      disabled={true}
                      type="text"
                      className="input-text"
                      id="compForR"
                      value={companies[data.compFor].name}
                    />
                  </>
                )}

                {/* <div className='checkbox-wrapper' style={{marginTop:'2em'}}>
                            <input className='checkbox' value={data.valStat} defaultChecked={data.valStat}
                                onChange={(e) => setData(prev => ({...prev, valStat: e.target.checked}))}
                                type="checkbox" id="valStat" name="valStat" title='Calcul valoare statistică'/>
                            <label htmlFor="valStat">Calcul valoare statistică
                            </label>
                        </div> */}
              </div>
            </div>
          )}

          {generating && (
            <>
              <FontAwesomeIcon className="generating-spin" icon={faSpinner} />
              <h2>Se generează..</h2>
            </>
          )}
        </div>
      )}
      {handle && <HandleExcel startData={data} />}
    </div>
  );
};

export default ImportExcel;
